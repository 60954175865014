import { useContext } from 'react';

import Head from 'next/head';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from 'contexts';

import { isEmpty } from 'utils/utility';

import Borrow from 'pages/borrow/index';

export default function Home() {
  return <Borrow />;
}
